import React, { useState, useRef } from "react";
import "./styles.css";
import ReactMarkdown from 'react-markdown';
import Footer from "./Footer";

const LandingPage = () => {
  const [resumeText, setResumeText] = useState("");
  const [evaluation, setEvaluation] = useState(null);
  const [photo, setPhoto] = useState(null);
  const textareaRef = useRef(null);
  const photoPreviewRef = useRef(null);

  const handleTextChange = (event) => {
    setResumeText(event.target.value);
    autoResizeTextarea();
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }
  };

  const evaluateResume = async () => {
    try {
      const textWithPhoto = photo ? "Соискатель прикрепил фото.\n" + resumeText : "Соискатель не прикрепил фото.\n" + resumeText;
      if (resumeText.length < 200) {
          console.error("Request Error: request is too short, " + resumeText.length + " symbols");
          alert("Request is too short: " + resumeText.length + " symbols\n200 is required");
      }
      else {
        const requestBody = { textWithPhoto };

        const response = await fetch("https://functions.yandexcloud.net/d4eu2993fe4u9pmt13gk", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
          console.log(response);
          throw new Error("Ошибка при отправке запроса");
        }

        const data = await response.json();
        setEvaluation(data.result.alternatives[0].message.text);
      }
    } catch (error) {
      console.error(error);
      setEvaluation(null);
    }
  };

  return (
    <div className="container">
      <div className="header">
        <img className="logo" src="./cveval.png" alt="CV Eval logo" />
        <h3 className="undertitle">Умный сервис для оценки резюме на основе ИИ</h3>
      </div>
      <div className="content">
        <div className="form">
          <div className="inputdiv">
            <textarea
              ref={textareaRef}
              className="textarea"
              placeholder="Введите текст резюме"
              value={resumeText}
              onChange={handleTextChange}
            />
            <div className="photoinputdiv">
              <label htmlFor="file-upload" className="file-upload-label">
                <input id="file-upload" type="file" accept="image/*" onChange={handlePhotoChange} />
                Загрузить фото
              </label>
              {photo && (
                <img src={photo} alt="Uploaded" className="photo-preview" ref={photoPreviewRef} />
              )}
            </div>
          </div>
          <button className="submit-button" onClick={evaluateResume}>Отправить на оценку</button>
        </div>
        {evaluation && (
          <div>
            <hr className="solid" />
            <h2 className="subtitle">Вердикт нейросети:</h2>
            <ReactMarkdown className="markdown">{evaluation}</ReactMarkdown>
          </div>
        )}
        <hr className="solid" />
        <div className="project-info">
          <h2 className="subtitle">О проекте</h2>
          <p>
            Добро пожаловать в мир CV Eval - надежного партнера в оценке резюме с использованием передовых технологий искусственного интеллекта!
          </p>
          <p>
            CV Eval - это не просто сервис, это инновационное решение, которое воплощает в себе многолетний опыт исследований в области машинного обучения и обработки естественного языка.
            Наша миссия - помочь рекрутерам и соискателям добиться наилучших результатов в процессе подбора персонала и поиска работы.
          </p>
          <p>
            Мы собрали команду экспертов, 24/7 готовых к анализу и оценке резюме. Эта команда - технологии. Наш сервис основан на передовых алгоритмах, которые позволяют не только выявлять ключевые аспекты резюме,
            но и анализировать их контекст, чтобы предоставить максимально точную и полезную обратную связь.
          </p>
          <p>
            Ключевое преимущество сервиса - глубокий анализ и формирование рекомендаций. При оценке резюме происходит сопоставление с требованиями рынка труда, учитываются Hard Skills и Soft Skills, а также навыки самопрезентации, представления и написания резюме.
          </p>
          <p>
            Присоединяйтесь к тем, кто уже оценил преимущества CV Eval! Начните использовать наш сервис бесплатно прямо сейчас и упростите процесс подбора персонала или поиска идеальной работы!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
